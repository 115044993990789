<script>
import { createDate, formatDate, isSameYear } from '/~/utils/format/date'
import { formatNumber } from '/~/utils/format/numeric'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerActions from '/~/components/drawer/components/actions/drawer-actions.v3.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { usePoints } from '/~/composables/points'

export default {
  name: 'profile-points',
  components: {
    BaseIcon,
    BaseLoader,
    BaseAsidePage,
    DrawerActions,
  },
  setup() {
    const { pointsProcessor, formattedPointsBalance } = usePoints()
    const { isDarkThemeForEwallet, ewalletLabels } = useCms()

    return {
      pointsProcessor,
      formattedPointsBalance,
      formatNumber,
      isDarkThemeForEwallet,
      ewalletLabels,
    }
  },
  computed: {
    recordGroupsByDate() {
      const groupsList = []

      this.pointsProcessor.hits.forEach((item) => {
        const date = createDate(item.transactionCreatedAt)
        const groupId = formatDate('daymonthyear', date)
        let groupName

        if (date.isToday()) {
          groupName = 'Today'
        } else if (date.isYesterday()) {
          groupName = 'Yesterday'
        } else {
          groupName = formatDate(
            date,
            isSameYear(item.transactionCreatedAt, new Date())
              ? 'daymonth'
              : 'daymonthyear'
          )
        }

        let groupObj = groupsList.find((group) => group.id === groupId)

        if (!groupObj) {
          groupObj = {
            id: groupId,
            name: groupName,
            date,
            records: [],
          }

          groupsList.push(groupObj)
        }

        groupObj.records.push({
          ...item,
          date,
        })
      })

      for (const groups of groupsList) {
        groups.records.sort((a, b) => b.date - a.date)
      }

      return groupsList.sort((a, b) => b.date - a.date)
    },
    actions() {
      return [
        {
          label: `Transfer ${this.ewalletLabels.ewalletPoints}`,
          description: 'Transfer to selected frequent flyer accounts',
          icon: 'plain/fly-2',
          route: { hash: '#profile-points-transfer' },
        },
        {
          label: `Buy additional ${this.ewalletLabels.ewalletPoints}`,
          description: 'Buy points with selected payment methods',
          icon: 'plain/medal-2',
          route: { hash: '#profile-points-buy' },
        },
      ]
    },
  },
  created() {
    this.pointsProcessor.refresh()
  },
  methods: {
    onAppend(isVisible) {
      if (!isVisible) return
      this.pointsProcessor.next()
    },
    getAmount(record) {
      const type = record.type
      const value = record.allocatedValue
      let sign = '-'

      if (type === 'earn') sign = '+'

      return `${sign}${this.formatNumber(value)}`
    },
    getTitle(record) {
      return record.eventReference || record.transactionId
    },
    getDescription(record) {
      return `${record.allocatedValue} Points ${
        record.type === 'earn' ? 'Earned' : record.type
      }`
    },
  },
}
</script>

<template>
  <base-aside-page :title="ewalletLabels.ewalletPoints">
    <div class="mx-auto flex items-center">
      <base-icon
        svg="v2/custom/points-token"
        :size="29"
        class="mr-2.5 text-primary"
      />
      <div class="text-3xl font-bold">{{ formattedPointsBalance }} PTS</div>
    </div>
    <div class="mx-auto mt-[5px] font-bold">Points Available</div>

    <drawer-actions
      :actions="actions"
      class="mt-11 space-y-4 rounded-md border p-5"
    />

    <div
      class="mt-11 font-bold"
      :class="{
        'text-eonx-neutral-600': !isDarkThemeForEwallet,
      }"
    >
      Activity
    </div>
    <div class="mt-4 space-y-5 rounded-md border p-5">
      <div
        v-if="pointsProcessor.isEmpty"
        class="flex items-center justify-center p-[30px]"
      >
        No transactions
      </div>
      <template v-else>
        <div
          v-for="group in recordGroupsByDate"
          :key="group.id"
          class="space-y-2.5"
        >
          <div
            class="text-sm font-bold"
            :class="{
              'text-eonx-neutral-600': !isDarkThemeForEwallet,
            }"
          >
            {{ group.name }}
          </div>
          <div
            v-for="record in group.records"
            :key="record.transactionId"
            class="flex items-center justify-between"
          >
            <div
              class="mr-5 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-primary text-white"
            >
              <base-icon svg="v2/custom/ewallet" :size="24" />
            </div>
            <div class="flex-1 overflow-hidden">
              <div class="font-bold">
                {{ getTitle(record) }}
              </div>
              <div class="text-eonx-neutral-600">
                {{ getDescription(record) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <div
        v-if="!pointsProcessor.allRecordsLoaded || pointsProcessor.isRefreshing"
        class="flex"
      >
        <base-loader key="observer" v-observe-visibility="onAppend" fullwidth />
      </div>
    </div>
  </base-aside-page>
</template>
